import{utilities}from'cv-react-core';import{componentBuilderFactory}from'./provider';/**
 * @class DialogComponentBuilder
 * @param {Object} data
 * @return {Object}
 */export default class DialogComponentBuilder{constructor(){this.dialogProps={isReadMode:true,enabledAnnotations:utilities.annotationHelper.none};this.componentProps={};}/**
     * @return {DialogComponentBuilder}
     */reset(){this.dialogProps={isReadMode:true,enabledAnnotations:utilities.annotationHelper.none};this.componentProps={};return this;}/**
     * DIALOG BASED PROPERTIES
     */ /**
     * @param {Boolean} isReadMode
     * @return {DialogComponentBuilder}
     */setReadMode(){let isReadMode=arguments.length>0&&arguments[0]!==undefined?arguments[0]:true;this.dialogProps.isReadMode=!!isReadMode;return this;}/**
     * @param {Object} value
     * @return {DialogComponentBuilder}
     */setProperty(value){this.dialogProps.property=value;return this;}/**
     * @param {Function} valueGetter
     * @return {DialogComponentBuilder}
     */setAsyncDataCallback(valueGetter){this.dialogProps.asyncDataCallback=valueGetter;return this;}/**
     * @param {Object} value
     * @return {DialogComponentBuilder}
     */setPropDef(value){this.dialogProps.propDef=value;return this;}/**
     * @param {Object} value
     * @return {DialogComponentBuilder}
     */setRecord(value){this.dialogProps.record=value;return this;}/**
     * @param {Array<Object>} value
     * @return {DialogComponentBuilder}
     */setRecords(value){this.dialogProps.records=value;return this;}/**
     * @param {Object} value
     * @return {DialogComponentBuilder}
     */setViewDef(value){this.dialogProps.viewDef=value;return this;}/**
     * @param {String} viewType
     * @return {DialogComponentBuilder}
     */setViewType(viewType){if(typeof viewType==='string'){this.dialogProps.viewType=viewType;}return this;}/**
     * @param {'horizontal' | 'vertical'} orientation
     * @return {DialogComponentBuilder}
     */setOrientation(orientation){this.dialogProps.orientation=orientation;return this;}/**
     * @param {Boolean} isCompactView
     * @return {DialogComponentBuilder}
     */setCompactState(){let isCompactView=arguments.length>0&&arguments[0]!==undefined?arguments[0]:true;this.dialogProps.isCompactView=!!isCompactView;return this;}/**
     * @param {String} mode
     * @return {DialogComponentBuilder}
     */setEnabledAnnotations(mode){if(typeof mode==='string'){this.dialogProps.enabledAnnotations=mode;}return this;}/**
     * @param {Boolean} isFocused
     * @return {DialogComponentBuilder}
     */setFocusedState(){let isFocused=arguments.length>0&&arguments[0]!==undefined?arguments[0]:true;this.dialogProps.isFocused=!!isFocused;return this;}/**
     * @param {Function} onValueChangeHandler
     * @return {DialogComponentBuilder}
     */setOnValueChangeHandler(onValueChangeHandler){if(typeof onValueChangeHandler==='function'){this.dialogProps.onValueChangeHandler=onValueChangeHandler;}return this;}/**
     * 
     * @param {Number} value 
     * @return {DialogComponentBuilder}
     */setFirstDayofWeek(value){this.dialogProps.firstDayofWeek=value;return this;}/**
     * @param {Function} valuesGetter
     * @return {DialogComponentBuilder}
     */setAvailableValuesLoader(valuesGetter){if(typeof valuesGetter==='function'){this.dialogProps.availableValuesLoader=valuesGetter;}return this;}/**
     * @param {values} values
     * @return {DialogComponentBuilder}
     */setAvailableValues(values){this.dialogProps.availableValues=values;return this;}/**
     * @param {Function} onPressHandler
     * @return {DialogComponentBuilder}
     */setActionOnPressHandler(onPressHandler){if(typeof onPressHandler==='function'){this.dialogProps.actionOnPressHandler=onPressHandler;}return this;}/**
     * @param {{ propertyName: String, value: String }} stagedValue
     * @return {DialogComponentBuilder}
     */setStagedValue(stagedValue){this.dialogProps.stagedValue=stagedValue;return this;}/**
     * @param {Boolean} shouldAutoSize
     * @return {DialogComponentBuilder}
     */setAutoGrow(){let shouldAutoSize=arguments.length>0&&arguments[0]!==undefined?arguments[0]:true;this.dialogProps.autoGrow=!!shouldAutoSize;return this;}/**
     * @param {Array} componentArray
     * @return {DialogComponentBuilder}
     */setTabSequenceArray(componentArray){if(Array.isArray(componentArray)){this.dialogProps.tabSequenceArray=componentArray;}return this;}/**
     * @param {Function} sequenceHandler
     * @return {DialogComponentBuilder}
     */setTabSequenceHandler(sequenceHandler){if(typeof sequenceHandler==='function'){this.dialogProps.tabSequenceHandler=sequenceHandler;}return this;}/**
     * @param {Function} refHandler
     * @return {DialogComponentBuilder}
     */setRefHandler(refHandler){if(typeof refHandler==='function'){this.dialogProps.refHandler=refHandler;}return this;}/**
     * @return {Object}
     */getDialogProps(){return this.dialogProps;}/**
     * COMPONENT BASED PROPERTIES
     */ /**
     * @param {String} key
     * @return {DialogComponentBuilder}
     */setKey(key){if(key){this.componentProps.key=key;}return this;}/**
     * @param {Object} style
     * @return {DialogComponentBuilder}
     */setStyle(style){if(style&&typeof style==='object'){this.componentProps.style=style;}return this;}/**
     * @param {Object} contextStyles
     * @return {DialogComponentBuilder}
     */setContextStyles(contextStyles){if(contextStyles&&typeof contextStyles==='object'){this.componentProps.contextStyles=contextStyles;}return this;}/**
     * @return {Object}
     */getContextStyles(){return this.componentProps.contextStyles;}/**
     * @param {String} toolTip
     * @returns {DialogComponentBuilder}
     */setToolTip(toolTip){if(toolTip){this.componentProps.toolTip=toolTip;}return this;}/**
     * @param {Boolean} isLoading
     * @return {DialogComponentBuilder}
     */setLoading(isLoading){this.componentProps.isLoading=isLoading;return this;}/**
     * @param {Object} propertyRef
     * @returns {DialogComponentBuilder}
     */setPropertyRef(propertyRef){if(propertyRef){this.componentProps.propertyRef=propertyRef;}return this;}/**
     * @return {Object}
     */getComponentProps(){return this.componentProps;}/**
     * @return {Object}
     */build(){// Get correct component builder model type
const componentBuilder=componentBuilderFactory(this.getDialogProps(),this.getContextStyles());// If a valid builder model instance was created
if(componentBuilder){// Set the component builder model instance properties
// and produce component element
const output=componentBuilder.setDialogProps(this.getDialogProps()).setProps(this.getComponentProps()).build();// Reset factory
this.reset();// Return component to executor
return output;}return null;}}