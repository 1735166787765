// Core component styles
const coreStyles={// We reduce padding for each container to address the issue with MUI
// adding too much padding by default. This can be overriden.
container:{display:'flex',flexGrow:1,flexShrink:1,padding:'unset'},input:{display:'flex',flexGrow:1,padding:'unset',whiteSpace:'break-spaces'},focused:{padding:'unset'},hoover:{padding:'unset'},disabled:{},notchedOutline:{},underline:{},underlineFocus:{},underlineHover:{}};/**
 * Helper method to get font styles for autoGrow input. Calculate all the available font properties to get the font style.
 * @param {Object} style - Styles for the component
 */const getFontStyles=style=>{const fontStyle=style.fontStyle?"".concat(style.fontStyle," "):'';const fontVariant=style.fontVariant?"".concat(style.fontVariant," "):'';const fontWeight=style.fontWeight?"".concat(style.fontWeight," "):'';const fontSize=style.fontSize?"".concat(style.fontSize,"px "):'16px ';const fontFamily=style.fontFamily||'Roboto';return style.font||"".concat(fontStyle).concat(fontVariant).concat(fontWeight).concat(fontSize).concat(fontFamily);};/**
 * Helper method to calculate width of the input element.
 * It uses HTML5 canvas.measureText to compute the width of the given text of given font.
 * @param {String} text - Text inside the text input
 * @param {String} font - Font style for autoGrow element
 */const getInputWidth=(text,font,iconOffsetWidth)=>{const canvas=getInputWidth.canvas||(getInputWidth.canvas=document.createElement('canvas'));const context=canvas.getContext('2d');context.font=font;const actualMetrics=context.measureText(text);const minMetrics=context.measureText('WWWW');return Math.max(actualMetrics.width+iconOffsetWidth,minMetrics.width);};/**
 * Helper method to get the width of autoGrow element.
 * @param {String} containerWidth - Width of the container
 * @param {Number} inputWidth - width of the input element
 */const getAutoGrowWidth=(containerWidth,inputWidth)=>{if(!containerWidth){return"".concat(inputWidth,"px");}const modifiedContainerWidth=containerWidth.toString().replace('px','');return"".concat(inputWidth>modifiedContainerWidth?modifiedContainerWidth:inputWidth,"px");};/**
 * Helper method to get styles for this component
 * @param {Object} contextStyles - Styles for this component
 * @param {Boolean} autoGrow - Grows/shrinks the input based on the size of its text value
 * @param {String} value - The text to populate in the input
 */const getStyles=function(){let contextStyles=arguments.length>0&&arguments[0]!==undefined?arguments[0]:{};let autoGrow=arguments.length>1&&arguments[1]!==undefined?arguments[1]:false;let value=arguments.length>2?arguments[2]:undefined;let iconOffsetWidth=arguments.length>3&&arguments[3]!==undefined?arguments[3]:0;const containerWidth=contextStyles.container&&contextStyles.container.width;// Get font styles for autoGrow element
const font=autoGrow&&getFontStyles({...coreStyles.input,...contextStyles.input});// AutoGrow elements width and font
const autoGrowStyles=autoGrow&&{width:getAutoGrowWidth(containerWidth,getInputWidth(value,font,iconOffsetWidth)),font};return{root:{...coreStyles.container,...contextStyles.container},input:{...coreStyles.input,...contextStyles.input,...autoGrowStyles,'&:hover':{...coreStyles.hoover,...contextStyles.hoover}},// notchedOutline: {
//     '&$notchedOutline': {
//         ...coreStyles.notchedOutline,
//         ...contextStyles.notchedOutline,
//     },
// },
focused:{'&$focused':{...coreStyles.focused,...contextStyles.input}},// disabled: {
//     '&$disabled': {
//         ...coreStyles.disabled,
//         ...contextStyles.disabled,
//     },
// },
underline:{'&&&:before':{...coreStyles.underline,...contextStyles.underline},'&&:after':{...coreStyles.underlineFocus,...contextStyles.underlineFocus},'&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before':{...coreStyles.underlineHover,...contextStyles.underlineHover}}};};export default getStyles;