// Core component styles
const coreStyles={container:{},inputContainer:{paddingTop:'4px'},inputText:{},inputUnderline:{},inputUnderlineFocus:{},inputUnderlineHover:{},icon:{},iconContainer:{},dialogContainer:{},dialogContentContainer:{},pickerToolbar:{},pickerToolbarButton:{},pickerToolbarButtonLabels:{},pickerDateTimeToolbarSeparator:{},pickerTabs:{},pickerTabButtons:{},pickerTabIndicator:{},pickerView:{},pickerCalendarSwitchHeader:{},pickerCalendarButton:{},pickerCalendarButtonIcon:{},pickerCalendarHeaderLabel:{},pickerCalendarDaysHeader:{},pickerCalendarDayLabel:{},pickerCalendarContainer:{},pickerCalendarWeek:{},pickerDay:{},pickerDayCurrent:{},pickerDaySelected:{},pickerDayDisabled:{},pickerDayHidden:{},pickerYearContainer:{},pickerYear:{},pickerYearSelected:{},pickerYearDisabled:{},pickerClockContainer:{},pickerClock:{},pickerClockPin:{},pickerClockNumber:{},pickerClockNumberSelected:{},pickerClockPointer:{},pickerClockPointerThumb:{},pickerClockPointerNoPoint:{},dialogActionsContainer:{},dialogActionsButtons:{},dialogActionsLabels:{}};/**
 * Helper method to get styles for this component
 * @param {Object} contextStyles - Styles for this component
 */const getStyles=function(){let contextStyles=arguments.length>0&&arguments[0]!==undefined?arguments[0]:{};return{// Main wrapper surrounding the input container
container:{...coreStyles.container,...contextStyles.container},// Text field input container
inputContainer:{...coreStyles.inputContainer,...contextStyles.inputContainer},// Text field input
inputText:{...coreStyles.inputText,...contextStyles.inputText},inputUnderline:{'&:before':{...coreStyles.inputUnderline,...contextStyles.inputUnderline},'&:after':{...coreStyles.inputUnderlineFocus,...contextStyles.inputUnderlineFocus},'&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before':{...coreStyles.inputUnderlineHover,...contextStyles.inputUnderlineHover}},// Text field icon
icon:{...coreStyles.icon,...contextStyles.icon},// Text field icon container
iconContainer:{...coreStyles.iconContainer,...contextStyles.iconContainer},// Picker modal
dialogContainer:{...coreStyles.dialogContainer,...contextStyles.dialogContainer,// Central picker modal content area containing:
// Year/Month/Day/Hour/Time/AmPm selection buttons
// Date/Time tabs
// Prev/Next month buttons
// Month/Year label
// Day of week labels
// Month view of days
'& .MuiDialogContent-root':{...coreStyles.dialogContentContainer,...contextStyles.dialogContentContainer},// Topmost picker modal date selection container containing:
// Year button
// Month/day button
// Hour/minute/ampm button
'& .MuiPickersToolbar-toolbar':{...coreStyles.pickerToolbar,...contextStyles.pickerToolbar,// Topmost picker modal year/month/day/time selection buttons
'& .MuiPickersToolbarButton-toolbarBtn':{...coreStyles.pickerToolbarButton,...contextStyles.pickerToolbarButton},// Topmost picker modal year/month/day/time selection button labels
'& .MuiPickersToolbarText-toolbarTxt':{...coreStyles.pickerToolbarButtonLabels,...contextStyles.pickerToolbarButtonLabels},// Topmost picker modal hour/minute separator (:)
'& .MuiPickerDTToolbar-separator':{...coreStyles.pickerDateTimeToolbarSeparator,...contextStyles.pickerDateTimeToolbarSeparator}},// Date/Time selection tabs between Picker Toolbar (year/month/day/time controls)
// and main calendar control month view content and buttons
'& .MuiPickerDTTabs-tabs':{...coreStyles.pickerTabs,...contextStyles.pickerTabs,// Tab button
'& .MuiTab-root':{...coreStyles.pickerTabButtons,...contextStyles.pickerTabButtons},// Tab button selected indicator
'& .MuiTabs-indicator':{...coreStyles.pickerTabIndicator,...contextStyles.pickerTabIndicator}},// Central picker modal content area containing:
// Prev/Next month buttons
// Month/Year label
// Day of week labels
// Month view of days
'& .MuiPickersBasePicker-pickerView':{...coreStyles.pickerView,...contextStyles.pickerView,// Calendar prev/next buttons and Month/Year label container
'& .MuiPickersCalendarHeader-switchHeader':{...coreStyles.pickerCalendarSwitchHeader,...contextStyles.pickerCalendarSwitchHeader},// Calendar prev/next buttons
'& .MuiPickersCalendarHeader-iconButton':{...coreStyles.pickerCalendarButton,...contextStyles.pickerCalendarButton,// Calendar prev/next button
'& .MuiTouchRipple-root':{},// Calendar prev/next button icon
'& .MuiIconButton-label':{...coreStyles.pickerCalendarButtonIcon,...contextStyles.pickerCalendarButtonIcon}},// Calendar Month/Year label container
'& .MuiPickersCalendarHeader-transitionContainer':{...coreStyles.pickerCalendarHeaderLabel,...contextStyles.pickerCalendarHeaderLabel},// Calendar days of week container
'& .MuiPickersCalendarHeader-daysHeader':{...coreStyles.pickerCalendarDaysHeader,...contextStyles.pickerCalendarDaysHeader},// Calendar days of week labels
'& .MuiPickersCalendarHeader-dayLabel':{...coreStyles.pickerCalendarDayLabel,...contextStyles.pickerCalendarDayLabel},// Calendar month view container below days of week container
'& .MuiPickersCalendar-transitionContainer':{...coreStyles.pickerCalendarContainer,...contextStyles.pickerCalendarContainer},// Calendar week row
'& .MuiPickersCalendar-week':{...coreStyles.pickerCalendarWeek,...contextStyles.pickerCalendarWeek},// Calendar day
'& .MuiPickersDay-day':{...coreStyles.pickerDay,...contextStyles.pickerDay},// Calendar day current day state
'& .MuiPickersDay-current':{...coreStyles.pickerDayCurrent,...contextStyles.pickerDayCurrent},// Calendar day selected state
'& .MuiPickersDay-daySelected':{...coreStyles.pickerDaySelected,...contextStyles.pickerDaySelected},// Calendar day disabled state
'& .MuiPickersDay-dayDisabled':{...coreStyles.pickerDayDisabled,...contextStyles.pickerDayDisabled},// Calendar hidden days
'& .MuiPickersDay-hidden':{...coreStyles.pickerDayHidden,...contextStyles.pickerDayHidden},// Calendar year selection container
'& .MuiPickersYearSelection-container':{...coreStyles.pickerYearContainer,...contextStyles.pickerYearContainer},// Calendar year item container
'& .MuiPickersYear-root':{...coreStyles.pickerYear,...contextStyles.pickerYear},// Calendar year item container selected state
'& .MuiPickersYear-yearSelected':{...coreStyles.pickerYearSelected,...contextStyles.pickerYearSelected},// Calendar year item container disabled state
'& .MuiPickersYear-yearDisabled':{...coreStyles.pickerYearDisabled,...contextStyles.pickerYearDisabled},// Main container surrounding the clock
'& .MuiPickersClock-container':{...coreStyles.pickerClockContainer,...contextStyles.pickerClockContainer},// Clock container
'& .MuiPickersClock-clock':{...coreStyles.pickerClock,...contextStyles.pickerClock},// Clock central pin
'& .MuiPickersClock-pin':{...coreStyles.pickerClockPin,...contextStyles.pickerClockPin},// Clock number
'& .MuiPickersClockNumber-clockNumber':{...coreStyles.pickerClockNumber,...contextStyles.pickerClockNumber},// Clock number selected state
'& .MuiPickersClockNumber-clockNumberSelected':{...coreStyles.pickerClockNumberSelected,...contextStyles.pickerClockNumberSelected},// Clock hand from center point to number
'& .MuiPickersClockPointer-pointer':{...coreStyles.pickerClockPointer,...contextStyles.pickerClockPointer},// Clock indicator center not on a number
'& .MuiPickersClockPointer-thumb':{...coreStyles.pickerClockPointerThumb,...contextStyles.pickerClockPointerThumb},// Clock indicator center on a number
'& .MuiPickersClockPointer-noPoint':{...coreStyles.pickerClockPointerNoPoint,...contextStyles.pickerClockPointerNoPoint}},// Bottom picker modal action area below content area containing:
// Cancel/Set buttons
'& .MuiDialogActions-root':{...coreStyles.dialogActionsContainer,...contextStyles.dialogActionsContainer,// Action button containers
'& .MuiButton-root':{...coreStyles.dialogActionsButtons,...contextStyles.dialogActionsButtons},// Action button labels
'& .MuiButton-label':{...coreStyles.dialogActionsLabels,...contextStyles.dialogActionsLabels}}}};};export default getStyles;